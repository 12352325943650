import styled from "styled-components";

export const Foot = styled.footer `
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #0B136A;
    color: #FFF;
    padding: 0.5rem 1rem;
    @media screen and (max-width: 450px){
        flex-direction: column;
        text-align: center;
    }
`
export const Div1 = styled.div `
    
`
export const Div2 = styled.div `
    a{
        color: #FFF;
        text-decoration: none;
    }
`