import styled from "styled-components";

export const Section = styled.section `
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    h3, h1{
        text-align: center;
    }
`