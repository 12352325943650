import React, { useState } from "react";
import { Body, Section, Div1, Div2 } from "./indexStyle";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import imagenes from "../../Assets/imagenes";
import FormComprador from "../FormComprador/FormComprador";
import FormAfiliados from "../FormAfiliados/FormAfiliados";
import FormNoAfiliados from "../FormNoAfiliados/formNoAfiliados";
import { ValidateNit } from "../hooks";
import FormSeminarios from "../FormSeminarios/FormSeminarios";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
}));

const Index = () => {
  //Capturando datos del select
  const [opciones] = useState([
    {
      value: "proveedorComprador",
      label: "Constructora",
    },
    {
      value: "proveedorAfiliado",
      label: "Proveedor Afiliado",
    },
    {
      value: "proveedorNoAfiliado",
      label: "Proveedor No Afiliado",
    },
    {
      value: "seminario",
      label: "Seminario",
    },
  ]);
  const [perfil, setPerfil] = useState({
    modalidad: "proveedorComprador",
  });
  //extraer los valores
  const { modalidad } = perfil;
  //leer los datos del formulario
  const obtenerInformacion = (e) => {
    setPerfil({
      ...perfil,
      [e.target.name]: e.target.value,
    });
  };
  const classes = useStyles();

  const [nit, setnit] = useState();
  const [existe, setexiste] = useState(false);

  const callbackExiste = (status) => {
    console.log("callback", status);
    setexiste(!existe);
  };

  const HandleChangeNit = (event) => {
    setnit(event.target.value);
  };

  return (
    <Body>
      <Section>
        <Div1>
          <img src={imagenes.evento} alt="" />
        </Div1>
        <Div2>
          <h2>Elegir perfil</h2>
          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel id="demo-simple-select-outlined-label">
              Tipo de proveedor
            </InputLabel>
            <Select
              fullWidth
              labelId="demo-simple-select-outlined-label"
              id="proveedorAfiliado"
              name="modalidad"
              label="Tipo de proveedor"
              variant="outlined"
              value={modalidad}
              onChange={obtenerInformacion}
            >
              {opciones.map((opciones) => (
                <MenuItem value={opciones.value}>{opciones.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {perfil.modalidad === "proveedorComprador" ? (
            <FormComprador
              HandleChangeNit={HandleChangeNit}
              nit={nit}
              callbackExiste={callbackExiste}
              existe={existe}
            />
          ) : null}
          {perfil.modalidad === "proveedorAfiliado" ? (
            <FormAfiliados
              HandleChangeNit={HandleChangeNit}
              nit={nit}
              callbackExiste={callbackExiste}
              existe={existe}
            />
          ) : null}
          {perfil.modalidad === "proveedorNoAfiliado" ? (
            <FormNoAfiliados
              HandleChangeNit={HandleChangeNit}
              nit={nit}
              callbackExiste={callbackExiste}
              existe={existe}
            />
          ) : null}
          {perfil.modalidad === "seminario" ? (
            <FormSeminarios
              HandleChangeNit={HandleChangeNit}
              nit={nit}
              callbackExiste={callbackExiste}
              existe={existe}
            />
          ) : null}
        </Div2>
      </Section>
    </Body>
  );
};
export default Index;
