import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
import Index from "./Pages/Inicio/Index";
import Afiliados from "./Pages/FormAfiliados/Afiliados";
import NoAfiliados from "./Pages/FormNoAfiliados/noAfiliados";
import Seminarios from "./Pages/FormSeminarios/Seminarios";
import Respuesta from "./Pages/Pagos/Respuesta";
import Footer from "./Components/Footer/Footer";
import { DateExcel } from "./Pages/ExportExcel/DateExcel";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
    < Navbar/>
      <Router>
        <Switch>
          <Route path="/" exact component={Index} />
          <Route path="/proveedor-afiliados" exact component={Afiliados} />
          <Route path="/formulario-seminarios" exact component={Seminarios} />
          <Route path="/proveedor-no-afiliados" exact component={NoAfiliados} />
          <Route path="/pagos/respuesta/" exact component={Respuesta} />
          <Route path="/descargar-datos-de-usuarios" exact component={DateExcel} />
        </Switch>
      </Router>
    <Footer/>  
    </>
  );
}

export default App;
