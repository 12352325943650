import React from 'react'
import imagenes from '../../Assets/imagenes';
import {Nav, Logo, LogoImg} from './navbarStyle';

const Navbar = () => {
    return (
      <Nav>
        <Logo href="/">
          <LogoImg src={imagenes.logo} />
        </Logo>
      </Nav>
    );
  };
export default Navbar;