import styled from "styled-components";

export const Body = styled.body `
    padding: 0;
`
export const Section = styled.section `
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: flex-start;
    @media screen and (max-width: 450px){
        flex-direction: column;
    }
`
export const Div1 = styled.div `
    width: 75%;
    align: center;
    img{
        width: 100%;
    }
    @media screen and (max-width: 450px){
        img{
            width: 100%;
        }
    }    
` 
export const Div2 = styled.div `
    width: 100%;
    padding: 2rem;
    h1, h2{
        text-align: center;
    }
` 