import axios from "axios";
import {
  toast
} from "react-toastify";

const urlCCI = "https://apilanding.camacolatlantico.org/api/cci_afiliados/";
const urlCamacol = "https://apilanding.camacolatlantico.org/api/companies/";
const saveusers = "https://apilanding.camacolatlantico.org/api/clientes";

const GuardaUser = (data) => {
  console.log("<<<<<<<<<<< SE EJECUTA>>>>>>>>>>>>>>>>>>")
  var formdata = new FormData();
  formdata.append("perfil", data.perfil);
  formdata.append("nombre", data.nombre);
  formdata.append("cedula", data.cedula);
  formdata.append("empresa", data.empresa);
  formdata.append("nit", data.nit);
  formdata.append("cargo", data.cargo);
  formdata.append("celular", data.celular);
  formdata.append("correo", data.correo);
  formdata.append("descripcion", data.descripcion ? data.descripcion : "NO APLICA");
  formdata.append("tipo_proveedor", data.tipo_proveedor ? data.tipo_proveedor : "NO APLICA");


  var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
  };

  fetch("https://apilanding.camacolatlantico.org/api/clientes", requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));


}

export const ValidateNit = (nit, callback, datauser, e) => {

  axios
    .get(`${urlCCI}${nit}`)
    .then(function (response) {
      console.log(response);

      //validacion camacol companies
      axios.get(`${urlCamacol}${nit}`).then((responsecamacol) => {

        if (datauser.perfil == "Constructora") {
          callback(e);
          GuardaUser(datauser);
        } else if (datauser.perfil == "Proveedor Afiliado" && responsecamacol.data !== "0" || response.data !== "0") {
          MSJERROR("La Empresa NO se encuentra registrada, porfavor escoga el perfil Proveedor NO Afiliado para registrarse");
        } else {
          callback(e);
          GuardaUser(datauser);
        }

      });
    })
    .catch(function (error) {
      console.log(error);
    });
};

function MSJERROR(response) {
  toast.configure();
  toast.error(response, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}


// si el nit no esta en la base de datos no lo deje pasar 
// si el nit existe se guarde en cliente y lo deja pasar