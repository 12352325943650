import React from "react";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import Swal from 'sweetalert2';
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core/";
import { ValidateNit } from "../hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(2),
      width: "auto",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  formControlRadio: {
    float: "left",
  },
}));

const FormSeminarios = (props) => {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => console.log(data);
  console.log(errors);

  const [value, setValue] = React.useState("");
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  //Credenciales de Email.js
  const SERVICE_ID = "service_33obibj";
  const TEMPLATE_ID = "template_ifnekgu";
  const USER_ID = "user_Y5ID6QD0N2Zc0izRmUYgi";

  //Enviar email
  function sendEmail(e) {
    e.preventDefault();

    emailjs
    .sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
      .then((result) => {
        console.log(result.text);
        Swal.fire({
          icon: 'success',
          title: 'Registro Exitoso'
        })
      }, (error) => {
        console.log(error.text);
        Swal.fire({
          icon: 'error',
          title: 'Ha ocurrido un error en el registro',
          text: error.text,
        })
      });
    e.target.reset()
  };

  /* function submitform(e) {
    e.preventDefault();
    ValidateNit(props.nit, () => {
      console.log("ok");
    });
    ValidateNit(props.nit, sendEmail, e);
  } */

  return (
    <div>
      <h1>Formulario Para Seminarios</h1>
      <form className={classes.root} onSubmit={sendEmail}>
      <input type="hidden" name="nombreFormulario" value={'Seminarios'} />
        <div>
          <TextField
            className={classes.formControl}
            id="outlined-basic"
            name="nombreCompleto"
            label="Nombre Completo"
            variant="outlined"
            type="text"
            {...register("nombreCompleto", {
              required: "Nombre Completo es requerido.",
            })}
            error={Boolean(errors.nombreCompleto)}
            helperText={errors.nombreCompleto?.message}
          />
        </div>
        <div>
          <TextField
            className={classes.formControl}
            id="outlined-basic"
            name="numeroCedula"
            label="Número de Cédula"
            variant="outlined"
            type="number"
            {...register("numeroCedula", {
              required: "Número de cédula es requerido.",
            })}
            error={Boolean(errors.numeroCedula)}
            helperText={errors.numeroCedula?.message}
          />
        </div>
        <div>
          <TextField
            className={classes.formControl}
            id="outlined-basic"
            name="correoElectronico"
            label="Correo Electronico"
            variant="outlined"
            type="email"
            {...register("correoElectronico", {
              required: "Número de celular es requerido.",
            })}
            error={Boolean(errors.correoElectronico)}
            helperText={errors.correoElectronico?.message}
          />
        </div>
        <div>
          <TextField
            className={classes.formControl}
            id="outlined-basic"
            name="numeroCelular"
            label="Celular"
            variant="outlined"
            type="tel"
            {...register("numeroCelular", {
              required: "Número de celular es requerido.",
            })}
            error={Boolean(errors.numeroCelular)}
            helperText={errors.numeroCelular?.message}
          />
        </div>
        <div>
          <FormControl component="fieldset">
            <FormLabel component="legend" className={classes.formControlRadio}>
              Afiliado
            </FormLabel>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={value}
              onChange={handleChange}
            >
              <FormControlLabel value="si" control={<Radio />} label="Si" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </div>
        <div>
          <TextField
            className={classes.formControl}
            id="outlined-basic"
            name="nombreEmpresa"
            label="Empresa"
            variant="outlined"
            type="text"
            {...register("nombreEmpresa", {
              required: "Nombre de empresa es requerido.",
            })}
            error={Boolean(errors.nombreEmpresa)}
            helperText={errors.nombreEmpresa?.message}
          />
        </div>
        <div>
          <TextField
            className={classes.formControl}
            id="outlined-basic"
            name="numeroNIT"
            label="NIT"
            variant="outlined"
            onChange={(e) => props.HandleChangeNit(e)}
            type="number"
            {...register("numeroNIT", { required: "NIT es requerido." })}
            error={Boolean(errors.numeroNIT)}
            helperText={errors.numeroNIT?.message}
          />
        </div>
        <div>
          <TextField
            className={classes.formControl}
            id="outlined-basic"
            name="correoFacturacion"
            label="Correo Facturación Electrónica"
            variant="outlined"
            type="email"
            {...register("correoFacturacion", {
              required: "Correo de facturación es requerido.",
            })}
            error={Boolean(errors.correoFacturacion)}
            helperText={errors.correoFacturacion?.message}
          />
        </div>
        <div>
          <TextField
            className={classes.formControl}
            id="outlined-basic"
            name="nombreFacturacion"
            label="Persona Contacto de Pagos"
            variant="outlined"
            type="text"
            {...register("nombreFacturacion", {
              required: "Nombre de contacto es requerido.",
            })}
            error={Boolean(errors.nombreFacturacion)}
            helperText={errors.nombreFacturacion?.message}
          />
        </div>
        <div>
          <TextField
            className={classes.formControl}
            id="outlined-basic"
            name="celularFacturacion"
            label="Celular"
            variant="outlined"
            type="tel"
            {...register("celularFacturacion", {
              required: "Número de celular es requerido.",
            })}
            error={Boolean(errors.celularFacturacion)}
            helperText={errors.celularFacturacion?.message}
          />
        </div>
        {value === 'no' ?
        <div>
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              multiple
              type="file"
          />
          <label htmlFor="contained-button-file"> 
            <Button variant="contained" color="primary" component="span">
              Adjuntar Archivo
            </Button>
          </label>
        </div> :null
        }
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className="btns"
        >
          Enviar
        </Button>
      </form>
    </div>
  );
};
export default FormSeminarios;
