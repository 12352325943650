import React, {useRef, useState } from "react";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import { makeStyles } from "@material-ui/core/styles";
import {
  OutlinedInput,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core/";
import { ValidateNit } from "../hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(2),
      width: "auto",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
}));

const FormNoAfiliados = (props) => {
  /**Capturando datos de nomnbre**/
  const [name, setName] = React.useState("");
  const wompiNameHandleChange = (event) => {
    setName(event.target.value);
  };
  /**Capturando datos de cedula**/
  const [cedula, setCedula] = React.useState("");
  const wompiCedulaHandleChange = (event) => {
    setCedula(event.target.value);
  };
  /**Capturando datos de correo**/
  const [correo, setCorreo] = React.useState("");
  const wompiCorreoHandleChange = (event) => {
    setCorreo(event.target.value);
  };
  /**Capturando datos de celular**/
  const [celular, setCelular] = React.useState("");
  const wompiCelularHandleChange = (event) => {
    setCelular(parseInt(event.target.value));
  };

  const [empresa, setempresa] = useState("");
  const [cargo, setcargo] = useState("");
  const [descripcion, setdescripcion] = useState("");
  const [tipo_proveedor, settipo_proveedor] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  /* const onSubmit = (data) => console.log(data);
    console.log(errors); */
  const classes = useStyles();

  const reference = "5TARN";
  const date = new Date();
  const currentDate = `${date.getDate()}${
    date.getMonth() + 1
  }${date.getFullYear()}${date.getMilliseconds()}`;

  //Credenciales de Email.js
  const SERVICE_ID = "service_33obibj";
  const TEMPLATE_ID = "template_ifnekgu";
  const USER_ID = "user_Y5ID6QD0N2Zc0izRmUYgi";
  
  //Enviar email
  const form = useRef();
  
    function sendEmail(e) {
      e.preventDefault();

      emailjs.sendForm('SERVICE_ID', 'TEMPLATE_ID', form.current, 'USER_ID')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  function noAfiliadosSubmit(e) {
    e.preventDefault();
    let datauser = {
      perfil: "No Afiliados",
      nombre: name,
      cedula,
      empresa,
      celular,
      correo,
      cargo,
      nit: props.nit,
    };
    // console.log("datos a enviar",datauser);
    ValidateNit(
      props.nit,
      () => {
        console.log("envio de email");
      },
      sendEmail,
      datauser,
      e
    );
  }

  return (
    <div>
      <h1>Proveedor No Afiliado</h1>
      <form
        ref={form}
        id="wompiform"
        className={classes.root}
        action="https://checkout.wompi.co/p/"
        method="GET"
      >
        {/* OBLIGATORIOS */}
        <input
          type="hidden"
          name="public-key"
          value="pub_prod_Gb56jCO7pPedwnSa50n8o3lzoUejBxmQ"
        />
        <input type="hidden" name="currency" value="COP" />
        <input type="hidden" name="amount-in-cents" value="23800000" />
        <input type="hidden" name="reference" value={reference + currentDate} />
        <input type="hidden" name="nombreFormulario" value={'Proveedor No Afiliado'} />
        <div>
          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel htmlFor="component-outlined">
              Nombre Completo
            </InputLabel>
            <OutlinedInput
              id="nombreCompleto"
              name="nombreCompleto"
              type="text"
              value={name}
              onChange={wompiNameHandleChange}
              label="Nombre Completo"
            />
          </FormControl>
          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel htmlFor="component-outlined">
              Número de Cédula
            </InputLabel>
            <OutlinedInput
              id="numeroCedula"
              name="numeroCedula"
              type="number"
              value={cedula}
              onChange={wompiCedulaHandleChange}
              label="Número de Cédula"
            />
          </FormControl>
          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel htmlFor="component-outlined">Empresa</InputLabel>
            <OutlinedInput
              id="nombreEmpresa"
              name="nombreEmpresa"
              type="text"
              onChange={(e) => setempresa(e.target.value)}
              label="Empresa"
            />
          </FormControl>
          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel htmlFor="component-outlined">NIT</InputLabel>
            <OutlinedInput
              id="numeroNIT"
              name="numeroNIT"
              type="text"
              onChange={(e) => props.HandleChangeNit(e)}
              label="NIT"
            />
          </FormControl>
          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel htmlFor="component-outlined">Cargo</InputLabel>
            <OutlinedInput
              id="cargo"
              name="cargo"
              type="text"
              onChange={(e) => setcargo(e.target.value)}
              label="Cargo"
            />
          </FormControl>
          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel htmlFor="component-outlined">Celular</InputLabel>
            <OutlinedInput
              id="numeroCelular"
              name="numeroCelular"
              type="number"
              value={celular}
              onChange={wompiCelularHandleChange}
              label="Celular"
            />
          </FormControl>
          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel htmlFor="component-outlined">
              Correo Electronico
            </InputLabel>
            <OutlinedInput
              id="correoElectronico"
              name="correoElectronico"
              type="email"
              value={correo}
              onChange={wompiCorreoHandleChange}
              label="Correo Electronico"
            />
          </FormControl>
          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel id="demo-simple-select-outlined-label">
              Tipo de proveedor
            </InputLabel>
            <Select
              onChange={(e) => settipo_proveedor(e.target.value)}
              fullWidth
              labelId="demo-simple-select-outlined-label"
              id="proveedorAfiliado"
              name="proveedorAfiliado"
              label="Tipo de proveedor"
              variant="outlined"
              {...register("proveedorAfiliado", {
                required: "Seleccionar tipo de proveedor afiliado.",
              })}
              error={Boolean(errors.proveedorAfiliado)}
              helperText={errors.proveedorAfiliado?.message}
            >
              <MenuItem value="servicios">Servicios</MenuItem>
              <MenuItem value="insumos">Insumos</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel id="demo-simple-select-outlined-label">
              Breve descripción del producto o servicio que ofrece
            </InputLabel>
            <OutlinedInput
              onChange={(e) => setdescripcion(e.target.value)}
              id="descripcion"
              name="descripcion"
              label="Breve descripción del producto o servicio que ofrece"
              multiline
              rows={4}
            />
          </FormControl>
        </div>

        {/* OPCIONALES */}
        <input
          type="hidden"
          name="redirect-url"
          value="https://landing.camacolatlantico.org/pagos/respuestas/"
        />
        <input type="hidden" name="customer-data:full-name" value={name} />
        <input type="hidden" name="customer-data:email" value={correo} />
        <input
          type="hidden"
          name="customer-data:phone-number"
          value={celular}
        />
        <input type="hidden" name="customer-data:legal-id-type" value="CC" />
        <input type="hidden" name="tax-in-cents:vat" value="3800000" />
        <input type="hidden" name="customer-data:legal-id" value={cedula} />
        <Button
          onClick={(e) => {
            noAfiliadosSubmit(e);
            document.getElementById("wompiform").submit();
          }}
          variant="contained"
          color="primary"
          type="submit"
          className="btns"
        >
          Enviar
        </Button>
      </form>
    </div>
  );
};
export default FormNoAfiliados;
