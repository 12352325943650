import React from 'react'
import { Section } from './respuestaStyle';

    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let idTransaccion = urlParams.get('id');

const Respuesta = () => {
    return (
        <Section>
            <div>
                <h1>Transacción Finalizada</h1>
            
                <h3>Información de la transacción</h3>
                        <h3>Transacción #:  
                        {idTransaccion}</h3>
            </div>
        </Section>
    )
}
export default Respuesta;