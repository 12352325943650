import React, {useEffect, useState, forwardRef } from 'react';
import axios from 'axios';
import ReactExport from "react-export-excel";
import MaterialTable from 'material-table';
import {Section} from './excelStyle';
import SearchIcon from '@material-ui/icons/Search';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import FilterListIcon from '@material-ui/icons/FilterList';
import ClearIcon from '@material-ui/icons/Clear';
import BackspaceIcon from '@material-ui/icons/Backspace';

const tableIcons = {
    Clear: forwardRef((props, ref) => <BackspaceIcon {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRightIcon {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeftIcon {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <SearchIcon {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPageIcon {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPageIcon {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterListIcon {...props} ref={ref} />),
  };

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const DateExcel = () => {

    const [data, setData] = useState([])
    const dateTable = [
        {title: "Nombre", field: "nombre"},
        {title: "Perfil", field: "perfil"},
        {title: "Cedula", field: "cedula"},
        {title: "Empresa", field: "empresa"},
        {title: "Tipo De Proveedor", field: "tipo_proveedor"},
        {title: "Descripcion", field: "descripcion"}
    ];

    const urlApi = 'https://apilanding.camacolatlantico.org/api/clientes';
    
    useEffect(() => {
        fetch(urlApi)
        .then(resp=>resp.json())
        .then(resp=>{
            setData(resp)
        })
    }, [])    
    return (
        <Section>
            <h1>Descargar datos de los usuarios registrados</h1>
            <ExcelFile element={<button>Descargar Datos</button>}>
                <ExcelSheet data={data} name="Clientes">
                    <ExcelColumn label="Nombre" value="nombre"/>
                    <ExcelColumn label="Perfil" value="perfil"/>
                    <ExcelColumn label="Cedula" value="cedula"/>
                    <ExcelColumn label="Empresa" value="empresa"/>
                    <ExcelColumn label="Tipo de proveedor" value="tipo_proveedor" />            
                    <ExcelColumn label="Descripcion" value="descripcion" />            
                </ExcelSheet>
            </ExcelFile>
            <MaterialTable
            icons={tableIcons}
            options={{
                search: true
              }}
                title="Clientes"
                data={data}
                columns={dateTable}
            />
        </Section>
    );
};
