import React, { useRef, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import { makeStyles } from "@material-ui/core/styles";
import Swal from 'sweetalert2';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  Button,
} from "@material-ui/core/";
import { ValidateNit } from "../hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(2),
      width: "auto",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
}));

const FormComprador = (props) => {
  console.log("for compador");
  
  //Clases de Material-UI
  const classes = useStyles();


  //Credenciales de Email.js
  const SERVICE_ID = "service_33obibj";
  const TEMPLATE_ID = "template_ifnekgu";
  const USER_ID = "user_Y5ID6QD0N2Zc0izRmUYgi";

  //Correo email-js
  const form = useRef();
  const [name, setName] = useState("");
  const [cedula, setCedula] = useState("");
  const [empresa, setempresa] = useState("");
  const [cargo, setcargo] = useState("");
  const [correo, setCorreo] = useState("");
  const [celular, setCelular] = useState("");
  const [descripcion, setdescripcion] = useState("");
  const [tipo_proveedor, settipo_proveedor] = useState("");

  function sendEmail(e) {
    e.preventDefault();

    emailjs
    .sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
      .then((result) => {
        console.log(result.text);
        Swal.fire({
          icon: 'success',
          title: 'Registro Exitoso'
        })
      }, (error) => {
        console.log(error.text);
        Swal.fire({
          icon: 'error',
          title: 'Ha ocurrido un error en el registro',
          text: error.text,
        })
      });
    e.target.reset()
  };

  function submitform(e) {
    e.preventDefault();
    let datauser = {
      perfil: "Constructora",
      nombre: name,
      cedula,
      empresa,
      celular,
      correo,
      cargo,
      nit:props.nit
    };
    // console.log("datos a enviar",datauser);
    ValidateNit(props.nit, sendEmail, datauser, e);
  }

  return (
    <div>
      <h1>Constructora</h1>
      <form className={classes.root} onSubmit={submitform}>
        {/* OBLIGATORIOS */}
        <input type="hidden" name="nombreFormulario" value={'Constructora'} />
        <div>
          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel htmlFor="component-outlined">Nombre Completo</InputLabel>
            <OutlinedInput
              id="nombreCompleto"
              name="nombreCompleto"
              type="text"
              onChange={(e) => setName(e.target.value)}
              label="Nombre Completo"
            />
          </FormControl>
          <FormControl className={classes.formControl} variant="outlined">
          <InputLabel htmlFor="component-outlined">Número de Cédula</InputLabel>
            <OutlinedInput
              id="numeroCedula"
              name="numeroCedula"
              type="number"
              onChange={(e) => setCedula(e.target.value)}
              label="Número de Cédula"
            />
          </FormControl>
          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel htmlFor="component-outlined">Empresa</InputLabel>
            <OutlinedInput
              id="nombreEmpresa"
              name="nombreEmpresa"
              type="text"
              onChange={(e) => setempresa(e.target.value)}
              label="Empresa"
            />
          </FormControl>
          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel htmlFor="component-outlined">NIT</InputLabel>
            <OutlinedInput
              id="numeroNIT"
              name="numeroNIT"
              type="text"
              onChange={(e) => props.HandleChangeNit(e)}
              label="NIT"
            />
          </FormControl>
          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel htmlFor="component-outlined">Cargo</InputLabel>
            <OutlinedInput
              id="cargo"
              name="cargo"
              type="text"
              onChange={(e) => setcargo(e.target.value)}
              label="Cargo"
            />
          </FormControl>
          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel htmlFor="component-outlined">Celular</InputLabel>
            <OutlinedInput
              id="numeroCelular"
              name="numeroCelular"
              type="number"
              onChange={(e) => setCelular(e.target.value)}
              label="Celular"
            />
          </FormControl>
          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel htmlFor="component-outlined">
              Correo Electronico
            </InputLabel>
            <OutlinedInput
              id="correoElectronico"
              name="correoElectronico"
              type="email"
              onChange={(e) => setCorreo(e.target.value)}
              label="Correo Electronico"
            />
          </FormControl>
        </div>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className="btns"
        >
          Enviar
        </Button>
      </form>
    </div>
  );
};
export default FormComprador;
