import React from 'react';
import {Body, Section, Div1, Div2} from './noAfiliadosStyle';
import imagenes from '../../Assets/imagenes';
import FormNoAfiliados  from './formNoAfiliados';

const NoAfiliados = () => {
    return (
        <Body>
            <Section>
                <Div1>
                    <img src={imagenes.evento} alt="" />
                </Div1> 
                <Div2>
                    <FormNoAfiliados/>
                </Div2>
            </Section>
        </Body>
    )
}
export default NoAfiliados;