import styled from "styled-components";

;
export const Nav = styled.div`
  padding: 0 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background: #00438C;
  position: static;
  top:0;
  height: auto;
  z-index: 1000;
  @media screen and (max-width: 450px){
    justify-content: center;
    align-content: center;
    align-items: center;
  }
`;
export const Logo = styled.a`
  margin: 0.5rem;
  padding:  0;
  text-decoration: none;
  font-weight: 800;
  font-size: 1.7rem;
  span {
    font-weight: 300;
    font-size: 1.3rem;
  }
`;
export const LogoImg = styled.img `
  
`
