import React from 'react';
import {Foot, Div1, Div2} from './footerStyle';

const Footer = () => {
    return (
        <Foot>
            <Div1>
                <p>
                    © 2021 Camacol Atlántico. All Rights Reserved.
                </p>
            </Div1>
            <Div2>
                <p>
                    Power By <a href="https://mutoestudio.com" target="_blank">Muto.</a>
                </p>
            </Div2>
        </Foot>
    )
}
export default Footer;